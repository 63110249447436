import React from "react";

import Layout from "../components/Layout";
import Header from "../components/Header-Other-Pages";
// import Scroll from '../components/Scroll';
import Footer from "../components/Footer";
// import Social from '../components/Social';

import demo2 from "../assets/images/ipad-screen.png";
import astore from "../assets/images/app-store-badge.svg";

const AboutPage = () => (
  <Layout>
    <Header />

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>ABOUT</h2>
          <p className="text-muted">How this project started.</p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="device-container">
              <div className="device-mockup ipad_air_2 landscape silver">
                <div className="device">
                  <div className="screen">
                    <img src={demo2} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="feature-item">
                  <i className="icon-screen-smartphone text-primary"></i>
                  <h3>History</h3>

                  <p className="text-muted">
                    In 2017 my daughter started daycare. I was surprised at how
                    much time went into handwriting notes on all the children's
                    activities during each day. I also thought it was a lot of
                    paper being used for each child. So I started creating this
                    app, and I hope other parents and daycares find it useful. I
                    hope this app can be used for the day to day sharing of all
                    the steps of development and day to day needs within
                    daycares operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">No More Paper!</h2>

            <p>
              Available on Apple&trade; iOS devices.
              <br></br>
              Download now to get started!
            </p>
            <div className="badges">
              <a className="badge-link" href="https://apps.apple.com/us/app/look-at-me-daycare/id1254621223">
                <img src={astore} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default AboutPage;
